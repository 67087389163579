import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import useOrganisationDetail from "../../zustand/useOrganisationDetail";
import { getOrganisationList } from "../../services/organisationService";
import useAuthDetails from "../../zustand/useAuthDetails";
import { ROLES } from "../../utils/constants";

const OrganisationDropdown = () => {
  const [organisationList, setOrganisationList] = useState([]);
  const selectedOrg = useOrganisationDetail((state) => state.selectedOrg);
  const setSelectedOrg = useOrganisationDetail((state) => state.setSelectedOrg);
  const userDetails = useAuthDetails((state) => state.userDetails);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getOrganisationList();

        if (userDetails.role === ROLES.admin) {
          setOrganisationList(
            data.filter((org) => org._id === selectedOrg._id)
          );
        } else {
          setOrganisationList(data);
        }
        if (data.length && !selectedOrg._id) {
          let getSelectedOrg = data[0];
          setSelectedOrg(getSelectedOrg);
        }
      } catch (err) {
        console.log(err);
        setOrganisationList([]);
        setSelectedOrg({});
      }
    })();
  }, [setSelectedOrg, selectedOrg._id, userDetails.role]);

  return (
    <>
      {organisationList.length === 1 ? (
        <></>
      ) : (
        <Autocomplete
          value={selectedOrg}
          onChange={(ev, v, r) => {
            if (v) setSelectedOrg(v);
          }}
          getOptionLabel={(opt) => opt.name || ""}
          options={organisationList}
          isOptionEqualToValue={(op, val) => op._id === val._id}
          sx={{ width: 250 }}
          renderInput={(params) => (
            <TextField {...params} label="Organisation" />
          )}
        />
      )}
    </>
  );
};

export default OrganisationDropdown;
