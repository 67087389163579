import apiClient from "../utils/apiClient";

export const loginUser = (userDetails) =>
  apiClient.post("api/auth/login", { ...userDetails });

export const logoutUser = () => apiClient.post("api/auth/logout");

export const verifyUserAndGetOtp = (userMobileNo) =>
  apiClient.post("api/otp/send/forgot-password", {
    mobileNumber: userMobileNo,
  });

export const submitOtpAndPassword = (userDetails) => {
  apiClient.post("api/forgot-password", { ...userDetails });
};
