import React, { useEffect, useState } from "react";
import LessonCardMobile from "../component/lesson/LessonCardMobile";
import { getLessonsByCategory } from "../services/lessonService";
import { Typography } from "@mui/material";

const LessonCategoryMobile = ({ teacherId, teacherCategory }) => {
  const [lessons, setLessons] = useState([]);
  const [playingLesson, setPlayingLesson] = useState("");

  useEffect(() => {
    (async () => {
      if (teacherId.length && teacherCategory.length) {
        try {
          const { data } = await getLessonsByCategory(
            teacherId,
            teacherCategory
          );
          setLessons(data);
        } catch (err) {
          console.log(err);
          setLessons([]);
        }
      }
    })();
  }, [teacherCategory, teacherId]);

  return (
    <section className="flex flex-col justify-center pb-24">
      {lessons.map((lesson) => (
        <LessonCardMobile
          playingLesson={playingLesson}
          setPlayingLesson={setPlayingLesson}
          key={lesson._id}
          imgId={lesson.imageFileId}
          audioFileId={lesson.audioFileId}
          lessonTitle={lesson.title}
          version={lesson.version}
          approvalStatus={lesson.approvalStatus}
          lessonId={lesson._id}
          subLessonsData={lesson.subLessonsData}
        />
      ))}
      {lessons.length === 0 && (
        <div className="rounded-md bg-white">
          <Typography className="text-center">
            No Lessons in this category!
          </Typography>
        </div>
      )}
    </section>
  );
};

export default LessonCategoryMobile;
