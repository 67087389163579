import { create } from "zustand";

const useSnackbarStore = create((set) => ({
  isOpen: false,
  message: "",
  type: "success",

  showSnackbar: (message, type = "success") => {
    set({ isOpen: true, message, type });
  },

  hideSnackbar: () => {
    set({ isOpen: false });
  },
}));

export default useSnackbarStore;
