import {
  Button,
  Link,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../services/authService";
import useAuthDetails from "../zustand/useAuthDetails";

const Login = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const setUserAuthentication = useAuthDetails(
    (state) => state.setUserAuthentication
  );
  const setUserDetails = useAuthDetails((state) => state.setUserDetails);
  // const { showSnackbar } = useSnackbarStore();

  const [userInfo, setUserInfo] = useState({
    mobileNumber: "",
    password: "",
  });

  function handleChange(ev) {
    const { name, value } = ev.target;
    setUserInfo((prev) => {
      return { ...prev, [name]: value };
    });
  }

  async function handleSubmit(ev) {
    ev.preventDefault();
    try {
      const { data } = await loginUser(userInfo);
      setUserAuthentication(true);
      setUserDetails(data);
      navigate("/organisations", {
        replace: true,
        state: { hasBeenFromLogin: true },
      });
    } catch (err) {
      setUserAuthentication(false);
      alert("Trouble logging in! Please try again.");
      console.log("Seems to be some issue", err);
    }
  }

  return (
    <div
      style={{
        minHeight: isMobile ? "calc(100vh - 56px)" : "calc(100vh - 64px)",
        display: "flex",
        backgroundImage: "url(/chalkboard.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper elevation={12} className="p-4 w-3/4 sm:w-3/12">
        <Typography variant="h4" align="center">
          Login
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            autoComplete="off"
            autoFocus
            name="mobileNumber"
            onChange={handleChange}
            value={userInfo.mobileNumber}
            label="Mobile Number"
            fullWidth
            margin="dense"
            InputLabelProps={{ shrink: true }}
            sx={{ "& .MuiInputBase-root": { borderRadius: 50 } }}
          />
          <TextField
            autoComplete="off"
            name="password"
            onChange={handleChange}
            value={userInfo.password}
            label="Password"
            type="password"
            fullWidth
            margin="dense"
            InputLabelProps={{ shrink: true }}
            sx={{ "& .MuiInputBase-root": { borderRadius: 50 } }}
          />
          <br />
          <br />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            // disabled={
            //   userInfo.mobileNumber.trim().length === 0 ||
            //   userInfo.password.trim().length === 0
            // }
          >
            Login
          </Button>
        </form>
        <div className="mt-2 text-center">
          <Link href="/forgot-password" variant="body2">
            Forgot Password? Click here
          </Link>
        </div>
      </Paper>
    </div>
  );
};

export default Login;
