import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getTeachersList } from "../../services/teacherService";
import useOrganisationDetail from "../../zustand/useOrganisationDetail";
import useTeacherDetails from "../../zustand/useTeacherDetails";
import { isNotEmptyObject } from "../../utils/helpers";

const TeachersDropdown = () => {
  const selectedOrg = useOrganisationDetail((state) => state.selectedOrg);
  const [teachersList, setTeachersList] = useState([]);
  const selectedTeacher = useTeacherDetails((state) => state.selectedTeacher);
  const setSelectedTeacher = useTeacherDetails(
    (state) => state.setSelectedTeacher
  );

  useEffect(() => {
    if (selectedOrg?._id) {
      (async () => {
        try {
          const { data } = await getTeachersList(selectedOrg._id);
          setTeachersList(data);
          if (data.length) {
            const oldTeacherSameOrg =
              data.find((teacher) => teacher._id === selectedTeacher._id) ?? {};
            if (isNotEmptyObject(oldTeacherSameOrg)) {
              setSelectedTeacher(oldTeacherSameOrg);
            } else {
              setSelectedTeacher(data[0]);
            }
          }
        } catch (err) {
          console.log("Error", err);
          setTeachersList([]);
          setSelectedTeacher({});
        }
      })();
    }
  }, [selectedOrg?._id, setSelectedTeacher, selectedTeacher._id]);

  return (
    <div>
      <Autocomplete
        value={selectedTeacher}
        onChange={(ev, v) => {
          setSelectedTeacher(v);
        }}
        getOptionLabel={(opt) => opt.name || ""}
        options={teachersList}
        isOptionEqualToValue={(op, val) => op._id === val._id}
        sx={{ width: 250 }}
        renderInput={(params) => <TextField {...params} label="Teacher" />}
      />
    </div>
  );
};

export default TeachersDropdown;
