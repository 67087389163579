export const themeSettings = {
  palette: {
    mode: "light",
    primary: {
      main: "#f4a014",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      // default: "#ffffff",
      // paper: "#4e4c4d",
    },
    text: {
      secondary: "#1e1d1d",
      primary: "rgba(146,125,125,0.87)",
      disabled: "rgba(173,164,164,0.38)",
    },
  },
};
