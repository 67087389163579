import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import StopIcon from "@mui/icons-material/Stop";
import {
  IconButton,
  Pagination,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { DeviceFrameset } from "react-device-frameset";
import "react-device-frameset/styles/marvel-devices.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import backgroundDesign from "../../assets/backgroundDesign.png";
import apiClient from "../../utils/apiClient";
import HighlightedLesson from "./HighlightedLesson";
import HighlightedLessonTable from "./HighlightedLessonTable";

export const MobileFrame = ({ subData }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [selectedSubLesson, setSelectedSubLesson] = useState(
    subData.length ? subData[0] : {}
  );
  const [selectedIndex, setSelectedIndex] = useState(subData.length ? 1 : null);
  const subLessonImageUrl =
    process.env.REACT_APP_SERVER_HOST +
    "/api/lessons/file/" +
    selectedSubLesson?.imageFileId;

  const [playAudio, setPlayAudio] = useState(false);
  const [audioSrc, setAudioSrc] = useState("");

  const audioRef = useRef(null);

  const handlePlay = () => {
    audioRef.current.onended = () => {
      setPlayAudio(false);
    };
    audioRef.current.onplay = () => {
      setPlayAudio(true);
    };
    audioRef.current.play();
  };

  const handleStop = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setPlayAudio(false);
  };

  const toggleAudio = (audPlay) => {
    if (audPlay) handlePlay();
    else handleStop();
  };

  useEffect(() => {
    if (selectedSubLesson?.audioFileId) {
      apiClient
        .get("api/lessons/web/file/" + selectedSubLesson.audioFileId, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: "audio/mpeg" });
          const objectURL = URL.createObjectURL(blob);
          setAudioSrc(objectURL);
        })
        .catch((error) => {
          console.error("Error fetching audio:", error);
        });
    }
    return () => {
      setAudioSrc("");
      handleStop();
      audioRef.current = "";
    };
  }, [selectedSubLesson.audioFileId]);

  return (
    <section className="flex flex-col items-center">
      {isMobile ? (
        <></>
      ) : (
        <>
          {audioSrc !== "" && (
            <audio ref={audioRef}>
              <source src={audioSrc} type="audio/mpeg" />
              <source src={audioSrc} type="audio/mp4" />
              Your browser does not support the audio element.
            </audio>
          )}
          <DeviceFrameset height={450} width={263} device="Galaxy Note 8">
            {subData.length && (
              <img
                src={subLessonImageUrl}
                style={{
                  height: "62%",
                  width: "100%",
                }}
                alt="sub lesson"
              />
            )}
            <p
              className="text-right text-white"
              style={{ background: "#f4a014" }}
            >
              <IconButton
                disabled={audioSrc === ""}
                onClick={() => toggleAudio(!playAudio)}
              >
                {playAudio ? (
                  <StopIcon className="text-red-600" fontSize="small" />
                ) : (
                  <RecordVoiceOverIcon fontSize="small" />
                )}
              </IconButton>
            </p>
            <section
              className="h-full p-2"
              style={{
                backgroundImage: `url(${backgroundDesign})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              {/* <p>Current Time: {currentTime.toFixed(5)} seconds</p> */}

              <div className="rounded-md bg-white p-2 mt-2">
                {selectedSubLesson.isTable === "true" ? (
                  <HighlightedLessonTable
                    lessonText={
                      selectedSubLesson.lessonText.includes("{")
                        ? JSON.parse(selectedSubLesson.lessonText)["title"]
                        : selectedSubLesson.lessonText
                    }
                    lessonTiming={selectedSubLesson.lessonTiming}
                    playAudio={playAudio}
                  />
                ) : (
                  <Typography variant="h6">
                    {/* {selectedSubLesson.lessonText} */}
                    <HighlightedLesson
                      lessonText={
                        selectedSubLesson.lessonText.includes("{")
                          ? JSON.parse(selectedSubLesson.lessonText)["title"]
                          : selectedSubLesson.lessonText
                      }
                      lessonTiming={selectedSubLesson.lessonTiming}
                      playAudio={playAudio}
                    />
                  </Typography>
                )}
              </div>
            </section>
          </DeviceFrameset>
          <br />
          <Pagination
            page={selectedIndex}
            onChange={(ev, pageNo) => {
              if (Number(pageNo)) {
                setSelectedSubLesson(subData[pageNo - 1]);
                setSelectedIndex(Number(pageNo));
              }
            }}
            count={subData.length}
            shape="rounded"
          />
        </>
      )}
    </section>
  );
};
