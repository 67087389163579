import React, { useEffect } from "react";
import Header from "../component/Header";
import MainArea from "../component/MainArea";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import apiClient from "../utils/apiClient";
import useAuthDetails from "../zustand/useAuthDetails";
import CustomSnackbar from "../component/CustomSnackbar";

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isUserLoggedIn = useAuthDetails((state) => state.isUserAuthenticated);

  const setUserAuthentication = useAuthDetails(
    (state) => state.setUserAuthentication
  );
  const setUserDetails = useAuthDetails((state) => state.setUserDetails);

  apiClient.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      if (err?.response) {
        if (err.response?.status === 401) {
          setUserDetails({});
          setUserAuthentication(false);
          navigate("/login");
        }
      }
      return Promise.reject(err);
    }
  );

  useEffect(() => {
    if (location.pathname === "/") {
      if (isUserLoggedIn) {
        navigate("/organisations");
      } else {
        navigate("/login");
      }
    }
  }, [isUserLoggedIn, location.pathname, navigate]);

  return (
    <div>
      <Header />
      <CustomSnackbar />
      <MainArea>
        <Outlet />
      </MainArea>
    </div>
  );
};

export default Home;
