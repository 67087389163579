import { create } from "zustand";
import { persist } from "zustand/middleware";

const useOrganisationDetail = create(
  persist(
    (set) => ({
      selectedOrg: {},
      setSelectedOrg: (org) => set(() => ({ selectedOrg: org })),
    }),
    { name: "org" }
  )
);

export default useOrganisationDetail;
