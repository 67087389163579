import { Paper } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import OrganisationListWithDropdown from "../component/teacher/OrganisationListWithDropdown";

const Teachers = () => {
  const { oId } = useParams();

  return (
    <div className="p-3">
      <Paper elevation={2} style={{ padding: 20 }}>
        <OrganisationListWithDropdown oId={oId} />
      </Paper>
    </div>
  );
};

export default Teachers;
