import apiClient from "../utils/apiClient";

export const getLessonsByCategory = (teacherId, lessonCategory) =>
  apiClient.get(`api/lessons?userId=${teacherId}&category=${lessonCategory}`);

export const getLessonsCount = (teacherId) =>
  apiClient.get(`api/lessons/count?userId=${teacherId}`);

export const getLessonFile = (fileId) =>
  apiClient.get("api/lessons/file/" + fileId);
