import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import BusinessIcon from "@mui/icons-material/Business";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useOrganisationDetail from "../../zustand/useOrganisationDetail";

const OrganisationList = ({ orgList = [] }) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const setSelectedOrg = useOrganisationDetail((state) => state.setSelectedOrg);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    setSelectedOrg(orgList.find((org) => org._id === index));
    navigate("/teachers");
  };

  return (
    <article>
      <section className="flex flex-col sm:flex sm:flex-row justify-between items-center">
        <div className="flex flex-row items-center gap-4">
          <BusinessIcon />
          <Typography variant="h5">List of Organisations</Typography>
        </div>

        <TextField
          label="Search here."
          variant="outlined"
          margin="normal"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </section>
      <List dense>
        {orgList
          .filter((orgData) => orgData.name.includes(searchTerm))
          .map((org) => (
            <ListItemButton
              divider
              key={org._id}
              selected={selectedIndex === org._id}
              onClick={(event) => handleListItemClick(event, org._id)}
            >
              <ListItemIcon>
                <ArrowForwardIcon />
              </ListItemIcon>
              <ListItemText primary={org.name} />
            </ListItemButton>
          ))}
      </List>
    </article>
  );
};

export default OrganisationList;
