import LocationCityIcon from "@mui/icons-material/LocationCity";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PeopleIcon from "@mui/icons-material/People";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import AuthHandler from "../screen/Auth/AuthHandler";
import useAuthDetails from "../zustand/useAuthDetails";
import { logoutUser } from "../services/authService";
import useDrawerDetails from "../zustand/useDrawerDetails";

const MainArea = ({ children }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isDrawerOpen = useDrawerDetails((state) => state.isDrawerOpen);
  const toggleDrawer = useDrawerDetails((state) => state.toggleDrawer);

  const setUserAuthentication = useAuthDetails(
    (state) => state.setUserAuthentication
  );
  const setUserDetails = useAuthDetails((state) => state.setUserDetails);

  const location = useLocation();
  const LINKS = [
    { text: "Organisations", href: "/organisations", icon: LocationCityIcon },
    { text: "Teachers", href: "/teachers", icon: PeopleIcon },
    { text: "Lessons", href: "/lessons", icon: MenuBookIcon },
  ];
  // const container =
  //   window !== undefined ? () => window().document.body : undefined;

  const avoidDrawerPaths = ["/login", "/forgot-password", "/create-password"];

  const DrawerContent = () => {
    return (
      <>
        <Divider />
        <List>
          {LINKS.map(({ text, href, icon: Icon }) => (
            <ListItemButton
              key={href}
              component={Link}
              to={href}
              selected={location.pathname === href}
              divider
              disablePadding
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          ))}
        </List>
        <section
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            // backgroundColor: "red",
            // width: "100%",
          }}
        >
          {/* <Divider /> */}
          <List>
            <ListItem
              key={"Logout"}
              disablePadding
              onClick={async () => {
                const { data } = await logoutUser();
                console.log("Logged out", data);
                setUserDetails({});
                setUserAuthentication(false);
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={"Logout"} />
              </ListItemButton>
            </ListItem>
          </List>
        </section>
      </>
    );
  };

  return (
    <div>
      {!avoidDrawerPaths.includes(location.pathname) && (
        <AuthHandler>
          <Drawer
            sx={{
              display: { xs: "none", sm: "block" },
              width: "20rem",
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: "20rem",
                boxSizing: "border-box",
                top: ["48px", "56px", "64px"],
                height: "auto",
                bottom: 0,
              },
            }}
            variant="permanent"
            anchor="left"
          >
            {DrawerContent()}
          </Drawer>
          {/* mobile drawer follows */}
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            onClose={toggleDrawer}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": { boxSizing: "border-box", width: 200 },
            }}
          >
            <section style={{ marginTop: 56 }}>{DrawerContent()}</section>
          </Drawer>
        </AuthHandler>
      )}
      <Box
        component="main"
        sx={{
          // flexGrow: 1,
          // bgcolor: "",
          ml: !avoidDrawerPaths.includes(location.pathname)
            ? isMobile
              ? 0
              : "20rem"
            : 0,
          mt: ["50px", "56px", "64px"],
          // p: 3,
        }}
        style={{
          minHeight: "calc(100vh - 64px)",
          backgroundColor: "#4e4c4d",
          // background:
          //   "radial-gradient(circle, rgba(241,186,95,1) 0%, rgba(244,160,20,1) 100%)",
        }}
      >
        {children}
      </Box>
    </div>
  );
};

export default MainArea;
