import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OrganisationList from "../component/organisation/OrganisationList";
import { getOrganisationList } from "../services/organisationService";
import { ROLES } from "../utils/constants";
import useAuthDetails from "../zustand/useAuthDetails";
import useOrganisationDetail from "../zustand/useOrganisationDetail";

const Organisations = () => {
  const [orgList, setOrgList] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const hasBeenFromLogin = state?.hasBeenFromLogin;
  const userDetails = useAuthDetails((state) => state.userDetails);
  const setSelectedOrg = useOrganisationDetail((state) => state.setSelectedOrg);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getOrganisationList();
        let showRelevantList = [];

        if (userDetails.role === ROLES.admin) {
          showRelevantList = data
            .filter((org) => org._id === userDetails.organizationId)
            .sort();
          setOrgList(showRelevantList);

          if (showRelevantList.length) {
            setSelectedOrg(showRelevantList[0]);
            if (showRelevantList.length === 1) {
              if (hasBeenFromLogin) navigate("/teachers");
            }
          } else {
            setSelectedOrg({});
          }
        }
        if (userDetails.role === ROLES.superadmin) {
          showRelevantList = data.sort();
          setOrgList(showRelevantList);
        }
      } catch (err) {
        console.log(err);
        setOrgList([]);
      }
    })();
  }, [
    userDetails.organizationId,
    userDetails.role,
    setSelectedOrg,
    navigate,
    hasBeenFromLogin,
  ]);

  return (
    <div className="p-3">
      <Paper elevation={2} style={{ padding: 20 }}>
        <OrganisationList orgList={orgList} />
      </Paper>
    </div>
  );
};

export default Organisations;
