import MenuBookIcon from "@mui/icons-material/MenuBook";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import StopIcon from "@mui/icons-material/Stop";
import { Badge, IconButton, Paper, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiClient from "../../utils/apiClient";

const LessonCardMobile = ({
  lessonTitle,
  imgId,
  version,
  approvalStatus,
  lessonId,
  subLessonsData,
  audioFileId,
  playingLesson,
  setPlayingLesson,
}) => {
  const navigate = useNavigate();
  const [playAudio, setPlayAudio] = useState(false);
  const [audioSrc, setAudioSrc] = useState("");

  const audioRef = useRef(null);

  // const handlePlay = () => {
  //   audioRef.current.onended = () => {
  //     setPlayAudio(false);
  //   };
  //   audioRef.current.play();
  // };

  // const handleStop = () => {
  //   if (audioRef.current) {
  //     audioRef.current.pause();
  //     audioRef.current.currentTime = 0;
  //   }
  //   setPlayAudio(false);
  // };

  // const approvalColor = {
  //   "IN PROGRESS": "#f4a015",
  //   ACCEPTED: "#00ab66",
  //   REJECTED: "#ff0000",
  // };
  const handlePlay = useCallback(() => {
    audioRef.current.onended = () => {
      setPlayingLesson("");
      setPlayAudio(false);
    };
    audioRef.current.onplay = () => {
      setPlayAudio(true);
    };
    audioRef.current.play();
    setPlayingLesson(lessonId);
  }, [lessonId, setPlayingLesson]);

  const handleStop = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setPlayAudio(false);
    // setPlayingLesson("");
  }, []);

  const toggleAudio = (audPlay) => {
    if (audPlay) handlePlay();
    else handleStop();
  };

  useEffect(() => {
    if (audioRef.current) {
      if (playingLesson === lessonId) {
        handlePlay();
      } else {
        handleStop();
      }
    }
  }, [playingLesson, lessonId, handleStop, handlePlay, lessonTitle]);

  useEffect(() => {
    if (audioFileId) {
      apiClient
        .get("api/lessons/web/file/" + audioFileId, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: "audio/mpeg" });
          const objectURL = URL.createObjectURL(blob);
          setAudioSrc(objectURL);
        })
        .catch((error) => {
          console.error("Error fetching audio:", error);
        });
    }

    return () => {
      setAudioSrc("");
      handleStop();
      audioRef.current = "";
    };
  }, [audioFileId, handleStop]);

  return (
    <Paper
      className="flex flex-col justify-between"
      style={{
        marginBottom: 8,
        borderRadius: 10,
        overflow: "hidden",
        cursor: "pointer",
      }}
    >
      {audioSrc !== "" && (
        <audio ref={audioRef}>
          <source src={audioSrc} type="audio/mpeg" />
          <source src={audioSrc} type="audio/mp4" />
          Your browser does not support the audio element.
        </audio>
      )}
      <div
        className="flex flex-row gap-2"
        onClick={() =>
          subLessonsData.length
            ? navigate("/lessons/view/" + lessonId, {
                state: { subLessonsData },
              })
            : alert("No Sub-lessons found!")
        }
      >
        {imgId && (
          <div style={{ minHeight: 120, minWidth: 120 }}>
            <img
              src={
                process.env.REACT_APP_SERVER_HOST + "/api/lessons/file/" + imgId
              }
              style={{ height: 120, width: 120 }}
              alt="lesson card"
            />
          </div>
        )}
        {/* <div className="p-2 mt-2"> */}
        <Typography variant="h6" className="mx-auto pt-2 pl-6 text-gray-700">
          {lessonTitle}
        </Typography>
        {/* </div> */}
      </div>
      <p className="text-right text-white" style={{ background: "#f4a014" }}>
        <IconButton
          disabled={audioSrc === ""}
          onClick={() => toggleAudio(!playAudio)}
        >
          {playAudio ? (
            <StopIcon className="text-red-600" fontSize="small" />
          ) : (
            <RecordVoiceOverIcon fontSize="small" />
          )}
        </IconButton>
        &nbsp; V{Number(version).toFixed(2)} &nbsp;
        <Badge
          onClick={() =>
            subLessonsData.length
              ? navigate("/lessons/view/" + lessonId, {
                  state: { subLessonsData },
                })
              : alert("No Sub-lessons")
          }
          className="mx-5"
          badgeContent={subLessonsData.length}
          color="secondary"
          showZero
        >
          <MenuBookIcon color="action" />
        </Badge>
      </p>
    </Paper>
  );
};

export default LessonCardMobile;
