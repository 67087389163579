import { Button, Paper, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { verifyUserAndGetOtp } from "../services/authService";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [userMobileNo, setUserMobileNo] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (ev) => {
    setLoading(true);
    ev.preventDefault();
    try {
      const { data } = await verifyUserAndGetOtp(userMobileNo);
      console.log("Debug", data?.otp);
      setLoading(false);
      navigate("/create-password", {
        state: {
          mobileNo: userMobileNo,
        },
      });
    } catch (err) {
      setLoading(false);
      console.log("Something went wrong", err);
    }
    setLoading(false);
  };

  return (
    <div
      style={{
        minHeight: "calc(100vh - 64px)",
        display: "flex",
        backgroundImage: "url(/chalkboard.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper elevation={12} className="p-4 w-3/4 sm:w-3/12">
        <Typography variant="h4" align="center">
          Reset Password
        </Typography>
        <br />
        <form onSubmit={handleSubmit}>
          <TextField
            label="Enter Mobile Number"
            value={userMobileNo}
            onChange={(e) => setUserMobileNo(e.target.value)}
            fullWidth
            margin="dense"
            sx={{ "& .MuiInputBase-root": { borderRadius: 50 } }}
          />

          <br />
          <br />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            disabled={loading}
          >
            {loading ? "Loading..." : "Get OTP"}
          </Button>
        </form>
      </Paper>
    </div>
  );
};

export default ForgotPassword;
