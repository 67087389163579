import { Divider, Link, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LessonCard from "../component/lesson/LessonCard";
import { getLessonsByCategory } from "../services/lessonService";
import { Link as RouterLink } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const LessonCategory = () => {
  const { teacherRefersCategory } = useParams();
  const [lessons, setLessons] = useState([]);
  const [teacherId, teacherCategory] = teacherRefersCategory.split("refers");
  const [playingLesson, setPlayingLesson] = useState("");

  useEffect(() => {
    (async () => {
      if (teacherId.length && teacherCategory.length) {
        try {
          const { data } = await getLessonsByCategory(
            teacherId,
            teacherCategory
          );
          setLessons(data);
        } catch (err) {
          console.log(err);
          setLessons([]);
        }
      }
    })();
  }, [teacherCategory, teacherId]);

  return (
    <div className="p-3">
      <Paper elevation={2} style={{ padding: 20 }}>
        <Typography variant="h4">Lessons</Typography>
        <br />
        <Divider />

        <Link component={RouterLink} to="/lessons">
          <ArrowBackIcon fontSize="small" /> Categories
          <br />
        </Link>

        <br />
        <section className="flex flex-row gap-4 flex-wrap">
          {lessons.map((lesson) => (
            <LessonCard
              playingLesson={playingLesson}
              setPlayingLesson={setPlayingLesson}
              key={lesson._id}
              imgId={lesson.imageFileId}
              audioFileId={lesson.audioFileId}
              lessonTitle={lesson.title}
              version={lesson.version}
              approvalStatus={lesson.approvalStatus}
              lessonId={lesson._id}
              subLessonsData={lesson.subLessonsData}
            />
          ))}
        </section>
      </Paper>
    </div>
  );
};

export default LessonCategory;
