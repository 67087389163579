import { Button, Paper, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { submitOtpAndPassword } from "../services/authService";

const CreatePassword = () => {
  const [formData, setFormData] = useState({
    otp: "",
    password: "",
    confirmedPassword: "",
  });
  const { otp, password, confirmedPassword } = formData;

  const navigate = useNavigate();
  const { state } = useLocation();
  const mobileNo = state?.mobileNo;
  console.log(mobileNo);

  const handleChange = (ev) => {
    setFormData((prev) => {
      return { ...prev, [ev.target.name]: ev.target.value };
    });
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    try {
      const { data } = await submitOtpAndPassword({
        ...formData,
        mobileNumber: "+177001652833",
      });
      console.log("data", data);
      alert("Password Changed Successfully! Redirecting to login page.");
      // console.log("Debug", data?.otp);
      navigate("/login");
    } catch (err) {
      console.log("Something went wrong", err);
    }
  };

  return (
    <div
      style={{
        minHeight: "calc(100vh - 64px)",
        display: "flex",
        backgroundImage: "url(/chalkboard.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper elevation={12} className="p-4 w-3/4 sm:w-3/12">
        <Typography variant="h4" align="center">
          Reset Password
        </Typography>
        <br />
        <form onSubmit={handleSubmit}>
          <TextField
            label="Enter OTP."
            name="otp"
            value={otp}
            onChange={handleChange}
            fullWidth
            margin="dense"
            sx={{ "& .MuiInputBase-root": { borderRadius: 50 } }}
          />

          <TextField
            name="password"
            type="password"
            label="Enter New Password"
            value={password}
            onChange={handleChange}
            fullWidth
            margin="dense"
            sx={{ "& .MuiInputBase-root": { borderRadius: 50 } }}
          />

          <TextField
            name="confirmedPassword"
            type="password"
            label="Confirm Password"
            value={confirmedPassword}
            onChange={handleChange}
            fullWidth
            margin="dense"
            sx={{ "& .MuiInputBase-root": { borderRadius: 50 } }}
          />

          <br />
          <br />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="large"
          >
            Change Password
          </Button>
        </form>
      </Paper>
    </div>
  );
};

export default CreatePassword;
