import React, { useCallback, useEffect, useState } from "react";

const HighlightedLesson = ({ lessonText, lessonTiming, playAudio }) => {
  const [highlightedText, setHighlightedText] = useState([]);

  const [elapsedTime, setElapsedTime] = useState(0);

  const isTimeInRange = useCallback(
    (startTime, endTime) => {
      const currentTime = elapsedTime;

      return currentTime >= startTime && currentTime <= endTime;
    },
    [elapsedTime]
  );

  useEffect(() => {
    if (playAudio) {
      let interval;

      interval = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 10);
      }, 10);

      return () => {
        clearInterval(interval);
        setElapsedTime(0);
      };
    }
  }, [playAudio]);

  useEffect(() => {
    const timings = lessonTiming.split(",").map(Number);

    const words = lessonText
      .trim()
      .split(/[\s,\t,\n]+/)
      .join(" ")
      .split(" ");

    const highlightedWords = words.map((word, index, wordsArr) => {
      const startTime = timings[index];
      const endTime =
        index < wordsArr.length - 1
          ? timings[index + 1]
          : timings[index] + 1000;

      const isHighlighted =
        startTime && endTime && isTimeInRange(startTime, endTime);

      return isHighlighted ? (
        <span key={index} className="text-red-500">
          {word + " "}
        </span>
      ) : (
        <span key={index}>{word + " "}</span>
      );
    });

    setHighlightedText(highlightedWords);
  }, [lessonText, lessonTiming, isTimeInRange]);

  return <>{highlightedText}</>;
};

export default HighlightedLesson;
