import CreatePassword from "./screen/CreatePassword";
import ForgotPassword from "./screen/ForgotPassword";
import LessonCategory from "./screen/LessonCategory";
import LessonView from "./screen/LessonView";
import Lessons from "./screen/Lessons";
import Login from "./screen/Login";
import Organisations from "./screen/Organisations";
import RootLayout from "./screen/RootLayout";
import Teachers from "./screen/Teachers";

export const pageRoutes = [
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/create-password",
        element: <CreatePassword />,
      },
      {
        path: "/organisations",
        element: <Organisations />,
      },
      {
        path: "/teachers",
        element: <Teachers />,
      },
      {
        path: "/teachers/:oId",
        element: <Teachers />,
      },
      {
        path: "/lessons",
        element: <Lessons />,
      },
      {
        path: "/lessons/view/:lessonId",
        element: <LessonView />,
      },
      {
        path: "/lessons/:teacherRefersCategory",
        element: <LessonCategory />,
      },
    ],
  },
];
