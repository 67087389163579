import { ThemeProvider, createTheme } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { pageRoutes } from "./pageRoutes";
import { themeSettings } from "./utils/themeSettings";

function App() {
  const theme = createTheme(themeSettings);
  const router = createBrowserRouter([...pageRoutes]);

  console.log("v1.1.x");
  const logError = (error, info) => {
    console.log("ErrorBoundary", error);
    console.log("ErrorBoundary info", info);
  };

  return (
    <div>
      <ErrorBoundary
        fallback={<div>Something went wrong!</div>}
        onError={logError}
      >
        <ThemeProvider theme={theme}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
