import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  SvgIcon,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useTeacherDetails from "../../zustand/useTeacherDetails";

function BottomNav({
  healthCount,
  farmingCount,
  literacyCount,
  businessCount,
}) {
  const location = useLocation();
  const teacherDetails = useTeacherDetails((state) => state.selectedTeacher);

  const searchParams = new URLSearchParams(location.search);

  const category = searchParams.get("category") ?? "";
  const [selectedNav, setSelectedNav] = useState(category ?? "");

  // const categories = ["Health", "Farming", "Literacy", "Business"];

  const HealthIcon = ({ isCurrSelected }) => {
    const iconColor = isCurrSelected ? "#ffffff" : "#c37f10";
    return (
      <SvgIcon fontSize="large">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 576 512"
          fill={iconColor}
        >
          <path d="M142.4 21.9c5.6 16.8-3.5 34.9-20.2 40.5L96 71.1V192c0 53 43 96 96 96s96-43 96-96V71.1l-26.1-8.7c-16.8-5.6-25.8-23.7-20.2-40.5s23.7-25.8 40.5-20.2l26.1 8.7C334.4 19.1 352 43.5 352 71.1V192c0 77.2-54.6 141.6-127.3 156.7C231 404.6 278.4 448 336 448c61.9 0 112-50.1 112-112V265.3c-28.3-12.3-48-40.5-48-73.3c0-44.2 35.8-80 80-80s80 35.8 80 80c0 32.8-19.7 61-48 73.3V336c0 97.2-78.8 176-176 176c-92.9 0-168.9-71.9-175.5-163.1C87.2 334.2 32 269.6 32 192V71.1c0-27.5 17.6-52 43.8-60.7l26.1-8.7c16.8-5.6 34.9 3.5 40.5 20.2zM480 224a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
        </svg>
      </SvgIcon>
    );
  };

  const FarmingIcon = ({ isCurrSelected }) => {
    const iconColor = isCurrSelected ? "#ffffff" : "#c37f10";
    return (
      <SvgIcon fontSize="large">
        <svg
          fill={iconColor}
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          width="800px"
          height="800px"
          viewBox="0 0 181.6 181.6"
        >
          <g>
            <g>
              <path
                d="M179.741,44.708c-0.041-0.051-0.05-0.105-0.095-0.155c-14.019-15.576-30.905-28.832-48.825-39.62
			c-0.278-0.167-0.542-0.24-0.794-0.263c-0.269-0.26-0.613-0.466-1.094-0.54c-20.18-3.113-22.937,31.988-20.481,44.793
			c0.159,0.831,0.563,1.359,1.076,1.652c0.116,0.817,0.281,1.629,0.501,2.436c-9.771,8.575-20.06,16.409-29.767,25.011
			c-3.682-4.476-7.749-8.362-12.123-12.237c-1.384-1.226-3.044-1.127-4.437,0c-21.145,17.117-42.8,33.604-62.661,52.238
			c-1.128,1.058-1.381,2.984-0.554,4.296c10.117,16.04,25.472,28.972,40.721,40.008c14.94,10.812,22.623,22.451,38.998,9.875
			c12.762-9.802,23.453-23.651,32.007-37.181c10.087-15.955-0.446-25.591-11.179-37.295c-1.044-1.139-2.311-1.227-3.36-0.741
			c8.857-8.886,19.024-16.143,26.963-26.075c0.12-0.151,0.155-0.315,0.243-0.472c16.417,20.95,51.304-1.56,56.594-22.094
			C181.948,46.494,181.021,45.237,179.741,44.708z M125.798,10.774c6.174,7.422,12.648,14.726,19.452,21.72
			c-4.725-3.941-9.333-7.984-13.931-12.082c0.004-0.005,0.007-0.01,0.01-0.015c1.152-1.578-1.23-3.064-2.537-1.958
			c-0.833,0.705-1.591,1.436-2.302,2.183c-0.884-1.167-2.175-2.003-3.352-2.847c-1.154-0.828-2.216-1.737-3.22-2.714
			C121.559,13.019,123.646,11.927,125.798,10.774z M153.955,46.695c1.13,0.952,2.198,2.017,3.333,2.969
			c-0.908,1.296-1.535,2.833-2.667,4.022c-1.504,1.579-3.597,2.26-5.672,2.671c-4.967,0.983-10.394-1.493-14.419-4.131
			c-10.996-7.206-10.978-18.941-5.075-28.943c4.032,5.435,9.293,10.171,14.268,14.624C147.073,40.906,150.515,43.797,153.955,46.695
			z M118.378,17.634c0.647,0.681,1.331,1.328,2.057,1.952c1.288,1.109,2.643,2.423,4.145,3.291
			c-1.371,1.798-2.458,3.673-3.229,5.602c-0.446-0.314-0.888-0.629-1.308-0.953c-1.46-1.129-2.868-2.321-4.203-3.595
			C116.462,21.75,117.327,19.653,118.378,17.634z M114.828,28.337c0.135-0.841,0.316-1.67,0.505-2.496
			c1.09,1.045,2.198,2.067,3.362,3.031c0.636,0.526,1.288,1.094,1.956,1.644c-0.504,1.739-0.807,3.501-0.862,5.268
			c-0.297-0.285-0.605-0.56-0.894-0.853c-1.452-1.477-2.873-2.984-4.323-4.463C114.65,29.757,114.715,29.047,114.828,28.337z
			 M86.676,89.445c2.17,1.808,2.84,4.242,3.926,6.501c-1.164,1.178-2.32,2.364-3.436,3.595c-4.209,4.641-8.447,9.229-12.971,13.566
			c-2.718,2.605-5.657,4.864-8.907,6.751c-8.206,4.765-4.224-9.275-3.632-10.66c3.979-9.311,10.76-16.642,18.376-23.363
			C82.435,86.655,84.707,87.804,86.676,89.445z M104.48,134.9c-5.437,8.599-12.327,16.32-19.31,23.68
			c-5.078,5.353-14.102,16.648-22.44,11.131c-10.182-6.738-20.313-14.627-29.578-22.509c-6.528-5.553-12.753-11.329-18.603-17.594
			c-6.995-7.492-6.819-8.224-0.183-14.272c4.288-3.908,8.733-7.644,13.158-11.394c8.993-7.623,18.241-14.944,27.32-22.462
			c1.578-1.307,9.816-10.495,12.627-8.258c3.205,2.549,6.206,5.281,9.185,8.071c-1.85,1.71-3.682,3.445-5.47,5.238
			c-8.476,8.5-20.769,21.653-16.44,34.816c4.137,12.579,18.527,0.579,23.62-4.065c6.151-5.611,11.481-12.073,17.171-18.133
			c0.129-0.138,0.267-0.265,0.396-0.401c-0.502,1.081-0.48,2.411,0.528,3.55C105.614,112.608,114.221,119.493,104.48,134.9z
			 M95.114,91.551c-1.204-2.281-3.104-4.347-4.818-5.726c-1.972-1.586-4.218-2.561-6.583-3.153c1.817-1.521,3.665-3.018,5.516-4.512
			c1.425,2.024,2.988,3.919,4.766,5.675c1.457,1.44,3.111,3.011,4.892,4.274C97.625,89.253,96.356,90.387,95.114,91.551z
			 M101.117,86.074c-1.535-1.161-3.237-2.172-4.709-3.359c-2.102-1.695-3.994-3.651-5.712-5.739
			c0.805-0.649,1.609-1.299,2.412-1.949c1.463,1.359,2.918,2.725,4.362,4.104c1.815,1.734,3.607,3.674,5.715,5.077
			C102.494,84.829,101.809,85.453,101.117,86.074z M105.119,82.459c-1.764-1.869-3.955-3.409-5.912-5.067
			c-1.481-1.256-3.02-2.437-4.551-3.628c1.15-0.939,2.273-1.895,3.402-2.847c1.367,1.709,2.791,3.36,4.387,4.88
			c1.466,1.396,3.352,3.196,5.366,4.183C106.915,80.81,106.022,81.641,105.119,82.459z M110.142,77.784
			c-1.736-1.471-3.925-2.566-5.651-4.033c-1.607-1.365-3.072-2.901-4.488-4.471c0.789-0.683,1.552-1.383,2.32-2.078
			c1.434,1.554,3.016,2.994,4.572,4.421c1.591,1.458,3.124,3.235,5.06,4.22c0.031,0.016,0.066,0.016,0.099,0.029
			C111.411,76.503,110.793,77.162,110.142,77.784z M114.017,73.908c-0.02-0.028-0.021-0.056-0.042-0.084
			c-1.341-1.75-3.25-2.933-5.001-4.258c-1.683-1.273-3.379-2.563-5.155-3.711c1.455-1.357,2.828-2.771,4.181-4.196
			c1.918,1.652,3.774,3.37,5.485,5.237c1.162,1.268,2.175,2.746,3.338,4.043C115.9,71.943,114.967,72.935,114.017,73.908z
			 M120.462,66.738c-0.451,0.565-0.938,1.094-1.404,1.643c-1.095-1.271-2.428-2.422-3.567-3.49c-1.877-1.759-3.854-3.395-5.89-4.964
			c1.343-1.504,2.644-3.037,3.835-4.644c1.27,1.737,2.573,3.448,3.936,5.124c1.391,1.71,2.847,3.648,4.438,5.41
			C121.336,65.944,120.873,66.224,120.462,66.738z M128.204,68.305c0.083-0.356,0.05-0.742-0.172-1.109
			c-1.873-3.092-5.029-5.57-7.466-8.237c-1.874-2.052-3.679-4.172-5.447-6.321c-0.199-1.264-0.546-2.484-0.703-3.719
			c0.066-0.846,0.092-1.701,0.108-2.559c3.306,4.219,6.607,8.442,9.977,12.612c3.243,4.014,6.693,8.775,10.697,12.577
			C132.801,70.901,130.452,69.862,128.204,68.305z M142.697,72.262c-4.022-5.137-9.339-9.533-13.718-14.356
			c-4.933-5.431-9.737-10.998-14.892-16.225c0.065-2.613,0.081-5.232,0.215-7.85c1.828,2.136,3.687,4.362,5.805,6.147
			c1.067,6.491,5.018,12.711,11.456,17.321c1.671,1.197,3.562,2.221,5.557,3.055c-0.122,0.322-0.078,0.693,0.277,1.007
			c2.634,2.326,5.081,4.848,7.64,7.256c0.935,0.879,1.942,1.82,3.003,2.694C146.271,71.783,144.488,72.149,142.697,72.262z
			 M151.433,70.216c-1.768-1.344-3.662-2.57-5.33-4.018c-1.729-1.5-3.423-3.036-5.146-4.54c2.797,0.717,5.683,0.951,8.453,0.598
			c1.669,0.995,3.237,2.112,4.729,3.361c0.79,0.662,1.547,1.452,2.358,2.145C154.853,68.708,153.16,69.542,151.433,70.216z
			 M158.769,66.346c-0.899-1.021-2.138-1.819-3.219-2.56c-1.124-0.77-2.311-1.438-3.521-2.062c1.257-0.382,2.576-0.964,3.851-1.682
			c1.482,1.45,3.095,2.764,4.817,3.916c0.215,0.144,0.455,0.301,0.706,0.461C160.541,65.085,159.672,65.746,158.769,66.346z
			 M162.585,60.727c-1.352-0.756-2.641-1.652-3.873-2.611c1.325-1.108,2.411-2.41,3.141-3.833c0.067,0.053,0.117,0.115,0.201,0.16
			c0.993,0.531,1.989,0.994,2.931,1.623c0.967,0.648,1.582,1.595,2.406,2.385c0.076,0.073,0.162,0.112,0.242,0.167
			c-0.959,1.068-1.973,2.099-3.03,3.085C163.938,61.369,163.229,61.087,162.585,60.727z M169.704,56.071
			c-1.129-2.228-4.371-3.76-6.567-4.195c-0.078-0.015-0.148,0-0.224-0.003c0.625-0.353,1.004-1.047,1.061-1.814
			c2.099,1.779,4.227,3.514,6.383,5.208C170.155,55.542,169.914,55.798,169.704,56.071z M172.378,52.251
			c-14.884-14.164-29.792-28.221-44.338-42.744c0.494-0.303,0.986-0.621,1.468-0.98c0.042-0.031,0.073-0.078,0.113-0.112
			c15.953,12.194,30.037,26.281,44.982,39.619C173.971,49.456,173.219,50.866,172.378,52.251z"
              />
              <path
                d="M53.189,87.279c-9.071,8.17-36.796,22.11-32.563,37.66c1.987,7.298,10.985,13.97,16.256,18.714
			c3.252,2.927,9.875,10.517,14.877,9.46c1.574-0.333,1.951-2.114,1.375-3.379c-1.604-3.521-7.171-6.095-10.055-8.548
			c-5.538-4.713-11.038-9.861-15.338-15.754c-6.757-9.258,22.465-29.145,27.93-35.669C57.115,88.039,54.891,85.746,53.189,87.279z"
              />
              <path
                d="M98.799,127.326c-5.167,7.798-12.906,13.58-19.238,20.398c-2.046,2.204-4.111,4.391-6.169,6.583
			c-0.666,0.709-1.376,1.371-1.965,2.147c-0.302,0.396-0.402,0.74-0.423,1.047c-0.262,0.261-0.463,0.624-0.439,0.955
			c0.4,5.482,9.715-4.33,10.478-5.085c7.641-7.553,16.538-14.27,22.576-23.229C105.638,127.153,100.781,124.336,98.799,127.326z"
              />
            </g>
          </g>
        </svg>
      </SvgIcon>
    );
  };

  const LiteracyIcon = ({ isCurrSelected }) => {
    const iconColor = isCurrSelected ? "#ffffff" : "#c37f10";
    return (
      <SvgIcon fontSize="large">
        <svg
          fill={iconColor}
          height="800px"
          width="800px"
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 490 490"
        >
          <g>
            <g>
              <g>
                <g>
                  <path d="M234.9,391.02V56.52c-63-13.6-126-20.2-189,9.7v334.4C108.9,370.72,171.9,377.42,234.9,391.02z" />
                  <g>
                    <path d="M444.1,400.72V66.22c-63-29.9-126-23.3-189-10.1v334.4C318.1,377.42,381.1,370.72,444.1,400.72z" />
                  </g>
                </g>
              </g>
              <path
                d="M463.9,105.92v326.3c-47.1-26.5-86.5-32.8-118.2-33.1c-29.8-0.2-81.2,8.7-100.7,14c-22.5-5.6-73.7-14.6-100.7-14
			c-33,0.7-62.6,3.9-118.2,33.1v-326.3H0v339.1h490v-339.1H463.9z"
              />
            </g>
          </g>
        </svg>
      </SvgIcon>
    );
  };

  const BusinessIcon = ({ isCurrSelected }) => {
    const iconColor = isCurrSelected ? "#ffffff" : "#c37f10";
    return (
      <SvgIcon fontSize="large">
        <svg
          width="800px"
          height="800px"
          fill={iconColor}
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill={iconColor}
            d="M704 704h64v192H256V704h64v64h384v-64zm188.544-152.192C894.528 559.616 896 567.616 896 576a96 96 0 1 1-192 0 96 96 0 1 1-192 0 96 96 0 1 1-192 0 96 96 0 1 1-192 0c0-8.384 1.408-16.384 3.392-24.192L192 128h640l60.544 423.808z"
          />
        </svg>
      </SvgIcon>
    );
  };

  const navStyle = {
    backgroundColor: "#f4a014",
    // color: "white",
    padding: 40,
  };

  // const categoryIcon = {
  //   Health: HealthIcon,
  //   Farming: FarmingIcon,
  //   Literacy: LiteracyIcon,
  //   Business: BusinessIcon,
  // };

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 10 }}
      elevation={3}
    >
      <BottomNavigation
        value={selectedNav}
        onChange={(ev, val) => setSelectedNav(val)}
        style={navStyle}
      >
        <BottomNavigationAction
          label=""
          value="Health"
          icon={
            <Badge
              className="mx-5"
              badgeContent={healthCount}
              color="secondary"
              showZero
            >
              <HealthIcon isCurrSelected={selectedNav === "Health"} />
            </Badge>
          }
          component={Link}
          to={"/lessons?teacher=" + teacherDetails._id + "&category=Health"}
        />
        <BottomNavigationAction
          label=""
          value="Farming"
          icon={
            <Badge
              className="mx-5"
              badgeContent={farmingCount}
              color="secondary"
              showZero
            >
              <FarmingIcon isCurrSelected={selectedNav === "Farming"} />
            </Badge>
          }
          component={Link}
          to={"/lessons?teacher=" + teacherDetails._id + "&category=Farming"}
        />
        <BottomNavigationAction
          label=""
          value="Literacy"
          icon={
            <Badge
              className="mx-5"
              badgeContent={literacyCount}
              color="secondary"
              showZero
            >
              <LiteracyIcon isCurrSelected={selectedNav === "Literacy"} />
            </Badge>
          }
          component={Link}
          to={"/lessons?teacher=" + teacherDetails._id + "&category=Literacy"}
        />
        <BottomNavigationAction
          label=""
          value="Business"
          icon={
            <Badge
              className="mx-5"
              badgeContent={businessCount}
              color="secondary"
              showZero
            >
              <BusinessIcon isCurrSelected={selectedNav === "Business"} />
            </Badge>
          }
          component={Link}
          to={"/lessons?teacher=" + teacherDetails._id + "&category=Business"}
        />
      </BottomNavigation>
    </Paper>
  );
}

export default BottomNav;
