import MenuBookIcon from "@mui/icons-material/MenuBook";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonIcon from "@mui/icons-material/Person";
import {
  Badge,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getTeachersList } from "../../services/teacherService";
import useOrganisationDetail from "../../zustand/useOrganisationDetail";
import OrganisationDropdown from "../organisation/OrganisationDropdown";
import useTeacherDetails from "../../zustand/useTeacherDetails";
import { useNavigate } from "react-router-dom";
import useSnackbarStore from "../../zustand/useSnackbarDetails";

const OrganisationList = () => {
  const selectedOrg = useOrganisationDetail((state) => state.selectedOrg);
  const setSelectedTeacher = useTeacherDetails(
    (state) => state.setSelectedTeacher
  );
  const navigate = useNavigate();
  const [teachersList, setTeachersList] = useState([]);
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar);

  useEffect(() => {
    if (selectedOrg?._id) {
      (async () => {
        const { data } = await getTeachersList(selectedOrg._id);
        if (data.length === 0)
          showSnackbar(
            "There are no teachers registered for this organisation. Please select another from the list above.",
            "error"
          );
        setTeachersList(data);
      })();
    }
  }, [selectedOrg?._id, showSnackbar]);

  return (
    <>
      <section className="p-2 flex flex-col sm:flex sm:flex-row gap-4 justify-between items-center">
        <div className="flex flex-row items-center gap-4">
          <PeopleAltIcon />
          <Typography variant="h5">List of Teachers</Typography>
        </div>

        <OrganisationDropdown />
      </section>
      <br />
      <List dense={true}>
        {teachersList.map((teacher) => (
          <>
            <ListItem
              key={teacher.mobileNumber}
              secondaryAction={
                <IconButton edge="end" aria-label="lessons-count">
                  <Badge
                    showZero
                    badgeContent={teacher.lessonCount}
                    color="secondary"
                  >
                    <MenuBookIcon color="action" />
                  </Badge>
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                onClick={(event) => {
                  setSelectedTeacher(teacher);
                  navigate(
                    "/lessons?teacher=" + teacher._id + "&category=Health"
                  );
                }}
              >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={teacher.name} />
              </ListItemButton>
            </ListItem>
            <Divider />
          </>
        ))}
      </List>
    </>
  );
};

export default OrganisationList;
