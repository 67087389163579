// Snackbar.js
import Snackbar from "@mui/material/Snackbar";
import useSnackbarStore from "../zustand/useSnackbarDetails";

function CustomSnackbar() {
  const { isOpen, message, hideSnackbar } = useSnackbarStore();

  const handleClose = () => {
    hideSnackbar();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={isOpen}
      autoHideDuration={5000}
      onClose={handleClose}
      message={message}
    />
  );
}

export default CustomSnackbar;
