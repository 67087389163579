import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, Paper, useMediaQuery } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MobileFrame } from "../component/lesson/MobileFrame";
import { MobileCarousel } from "../component/lesson/MobileCarousel";

const LessonView = () => {
  //   const { lessonId } = useParams();
  const { state } = useLocation();
  const isMobile = useMediaQuery("(max-width:600px)");

  const navigate = useNavigate();
  const subData = [...state.subLessonsData] ?? [];

  return (
    <div style={{ height: "100%" }}>
      {isMobile ? (
        <MobileCarousel subLessonArr={subData} />
      ) : (
        <div className="p-3">
          <Paper elevation={2} style={{ padding: 20 }}>
            <Link className="cursor-pointer" onClick={() => navigate(-1)}>
              <ArrowBackIcon fontSize="small" /> Lessons
            </Link>

            <MobileFrame subData={subData} />
          </Paper>
        </div>
      )}
    </div>
  );
};

export default LessonView;
