import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import React from "react";
import headerLogo from "../assets/headerLogo.png";
import { IconButton, useMediaQuery } from "@mui/material";
import useDrawerDetails from "../zustand/useDrawerDetails";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Header = () => {
  const toggleDrawer = useDrawerDetails((state) => state.toggleDrawer);
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <div>
      <AppBar position="fixed" sx={{ zIndex: 2000 }}>
        <Toolbar className="flex flex-row justify-center sm:justify-start items-center">
          {isMobile && location.pathname.startsWith("/lessons/view/") ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => navigate(-1)}
              sx={{
                // mr: 2,
                display: { sm: "none" },
                position: "fixed",
                // top: 0,
                left: 15,
              }}
            >
              <ArrowBackIcon className="text-white" />
            </IconButton>
          ) : (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={toggleDrawer}
              sx={{
                // mr: 2,
                display: { sm: "none" },
                position: "fixed",
                // top: 0,
                left: 15,
              }}
            >
              <MenuIcon className="text-white" />
            </IconButton>
          )}
          <img
            className="p-2"
            src={headerLogo}
            alt="iTeach-logo"
            style={{ height: 55, width: 150 }}
          />
          <div></div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
