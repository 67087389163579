import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import StopIcon from "@mui/icons-material/Stop";
import { IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useRef, useState } from "react";
import "react-device-frameset/styles/marvel-devices.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import backgroundDesign from "../../assets/backgroundDesign.png";
import apiClient from "../../utils/apiClient";
import HighlightedLesson from "./HighlightedLesson";
import HighlightedLessonTable from "./HighlightedLessonTable";

// const renderTableForLesson = (lessonText) => {
//   const splitLessonText = lessonText.split(",");
//   return (
//     <table className="w-full text-center">
//       <tr>
//         <td>{splitLessonText[0]}</td>
//         <td>{splitLessonText[1]}</td>
//         <td>{splitLessonText[2]}</td>
//       </tr>
//       <tr>
//         <td>{splitLessonText[3]}</td>
//         <td>{splitLessonText[4]}</td>
//         <td>{splitLessonText[5]}</td>
//       </tr>
//       <tr>
//         <td>{splitLessonText[6]}</td>
//         <td>{splitLessonText[7]}</td>
//         <td>{splitLessonText[8]}</td>
//       </tr>
//     </table>
//   );
// };

export function MobileCarousel({ subLessonArr = [] }) {
  return (
    <>
      <Carousel
        autoPlay={false}
        infiniteLoop={false}
        showArrows={false}
        showThumbs={false}
      >
        {subLessonArr.map((subLess) => (
          <CarouselItem key={subLess._id} eachSubLesson={subLess} />
        ))}
      </Carousel>
    </>
  );
}

function CarouselItem({ eachSubLesson }) {
  const [playAudio, setPlayAudio] = useState(false);
  const [audioSrc, setAudioSrc] = useState("");

  const audioRef = useRef(null);

  const handlePlay = () => {
    audioRef.current.onended = () => {
      setPlayAudio(false);
    };
    audioRef.current.onplay = () => {
      setPlayAudio(true);
    };
    audioRef.current.play();
  };

  const handleStop = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setPlayAudio(false);
  };

  const toggleAudio = (audPlay) => {
    if (audPlay) handlePlay();
    else handleStop();
  };

  useEffect(() => {
    if (eachSubLesson?.audioFileId) {
      apiClient
        .get("api/lessons/web/file/" + eachSubLesson.audioFileId, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: "audio/mpeg" });
          const objectURL = URL.createObjectURL(blob);
          setAudioSrc(objectURL);
        })
        .catch((error) => {
          console.error("Error fetching audio:", error);
        });
    }
    return () => {
      setAudioSrc("");
      handleStop();
      audioRef.current = "";
    };
  }, [eachSubLesson.audioFileId]);

  return (
    <div>
      <Box
        component="img"
        sx={{
          height: 280,
          display: "block",
          overflow: "hidden",
          width: "100%",
        }}
        src={
          process.env.REACT_APP_SERVER_HOST +
          "/api/lessons/file/" +
          eachSubLesson.imageFileId
        }
        alt={eachSubLesson._id}
      />

      <div
        className="text-right text-white w-full mr-2"
        style={{ background: "#f4a014" }}
      >
        {audioSrc !== "" && (
          <audio ref={audioRef}>
            <source src={audioSrc} type="audio/mpeg" />
            <source src={audioSrc} type="audio/mp4" />
            Your browser does not support the audio element.
          </audio>
        )}
        <IconButton
          disabled={audioSrc === ""}
          onClick={() => toggleAudio(!playAudio)}
        >
          {playAudio ? (
            <StopIcon className="text-red-600" fontSize="small" />
          ) : (
            <RecordVoiceOverIcon fontSize="small" />
          )}
        </IconButton>
        &nbsp;
      </div>
      <section
        className="p-2"
        style={{
          height: 250,
          backgroundImage: `url(${backgroundDesign})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="rounded-md bg-white p-2 mt-2">
          {eachSubLesson.isTable === "true" ? (
            <HighlightedLessonTable
              lessonText={
                eachSubLesson.lessonText.includes("{")
                  ? JSON.parse(eachSubLesson.lessonText)["title"]
                  : eachSubLesson.lessonText
              }
              lessonTiming={eachSubLesson.lessonTiming}
              playAudio={playAudio}
            />
          ) : (
            <Typography variant="h6">
              <HighlightedLesson
                lessonText={
                  eachSubLesson.lessonText.includes("{")
                    ? JSON.parse(eachSubLesson.lessonText)["title"]
                    : eachSubLesson.lessonText
                }
                lessonTiming={eachSubLesson.lessonTiming}
                playAudio={playAudio}
              />
            </Typography>
          )}
        </div>
      </section>
      <br />
    </div>
  );
}
