import React from "react";
import { Navigate } from "react-router-dom";
import useAuthDetails from "../../zustand/useAuthDetails";

const AuthHandler = ({ children }) => {
  const isUserLoggedIn = useAuthDetails((state) => state.isUserAuthenticated);
  return <div>{isUserLoggedIn ? children : <Navigate to={"/login"} />}</div>;
};

export default AuthHandler;
