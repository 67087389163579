import { create } from "zustand";
import { persist } from "zustand/middleware";

const useAuthDetails = create(
  persist(
    (set) => ({
      isUserAuthenticated: false,
      setUserAuthentication: (authValueBool) =>
        set(() => ({ isUserAuthenticated: authValueBool })),
      userDetails: {},
      setUserDetails: (uDetails) => set(() => ({ userDetails: uDetails })),
    }),
    { name: "user" }
  )
);

export default useAuthDetails;
